module.exports = [{
      plugin: require('/data/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-214188900-1","head":true,"anonymize":true,"exclude":["/preview/**","/internal-preview/**"]},
    },{
      plugin: require('/data/node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"sigvc","defaultLang":"en-de","path":"/preview","previews":true,"pages":[{"type":"Text_page","match":"/internal-preview/:uid","path":"/internal-preview","component":"/data/src/templates/text-page.js"},{"type":"Page","match":"/internal-preview/:uid","path":"/internal-preview","component":"/data/src/templates/page.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('/data/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000000"},
    },{
      plugin: require('/data/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
