// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/data/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-text-page-js": () => import("/data/src/templates/text-page.js" /* webpackChunkName: "component---src-templates-text-page-js" */),
  "component---src-templates-page-js": () => import("/data/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-contact-js": () => import("/data/src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-homepage-js": () => import("/data/src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-pages-404-js": () => import("/data/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

